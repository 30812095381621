import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day57 from "./dia-09-may";
import Day58 from "./dia-10-may";
import Day59 from "./dia-11-may";
import Day60 from "./dia-12-may";
import Day61 from "./dia-13-may";
import Day62 from "./dia-14-may";
import Day63 from "./dia-15-may";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <Day63 {...props} />
      <Day62 {...props} />
      <Day61 {...props} />
      <Day60 {...props} />
      <Day59 {...props} />
      <Day58 {...props} />
      <Day57 {...props} />
    </LayoutWeek>
  );
};

export default Week;
